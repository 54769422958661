/** @module lib/utils/random */
/**
 * Retourne un booléen au hasard (true|false tiré à pile ou face)
 */
export function randomBool (): boolean {
  return Math.random() < 0.5
}

/**
 * Retourne un entier compris entre min et max (inclus)
 * @param {number} min
 * @param {number} max
 * @return {number}
 * @throws TypeError si min ou max ne sont pas entiers, ou si min > max
 */
export function randomInt (min: number, max: number): number {
  if (!Number.isInteger(min) || !Number.isInteger(max) || min > max) throw TypeError(`Arguments invalides (${min} et ${max})`)
  if (min === max) return min
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Retourne un des éléments de arr pris au hasard
 * @param arr
 */
export function randomElement<T> (arr: T[]): T | undefined {
  if (!Array.isArray(arr)) throw TypeError('randomElement prend un tableau en argument')
  if (!arr.length) return undefined
  return arr[randomInt(0, arr.length - 1)]
}

/**
 * Retourne n éléments de arr pris au hasard
 * @param arr Le tableau dans lequel piocher
 * @param n Le nb d’éléments à retourner
 */
export function randomElements<T> (arr: T[], n: number): T[] {
  if (!Array.isArray(arr)) throw TypeError('randomElement prend un tableau en argument')
  if (!Number.isInteger(n) || n < 1) throw TypeError('Le nombre d’éléments doit être un entier strictement positif')
  if (n < arr.length) throw Error(`Impossible de choisir ${n} éléments dans un tableau de longueur ${arr.length}`)
  const pioche = [...arr]
  const choix: T[] = []
  while (choix.length < n) {
    const indexChoisi = randomInt(0, pioche.length - 1)
    const eltChoisi = pioche[indexChoisi]
    // on ajoute l’élément choisi
    if (eltChoisi != null) {
      choix.push(eltChoisi)
      // on le vire de la pioche
      pioche.splice(indexChoisi, 1)
    }
  }
  return choix
}
